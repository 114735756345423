import React from "react"

import Layout from "../components/layout"
import SeO from "../components/seo"
import Header from "../components/header"
import Button from "../components/button"

import { FaInstagram } from "react-icons/fa"
import { MdFlashOn } from "react-icons/md"

const NotFoundPage = () => (
  <Layout>
      <SeO title="Merci" />

      <Header 
          title="Merci !"
          subtitle="Votre message a bien été envoyé"
          intro="Notre équipe reviendra vers vous dans les meilleurs délais."
      />

    <div className="container">
      <Button link={"/"} text={"Retournez à la page d'accueil"} icon={<MdFlashOn />} />
      <br/>
      <Button link={"https://www.instagram.com/lyketil/"} text={"Suivez-nous sur Instagram"} icon={<FaInstagram />} />
    </div>
  </Layout>
)

export default NotFoundPage
